.email-container{
    height: 500px;
    width: 100%;
    background-color: rgb(24, 23, 23);
    border-top:  solid 1px black;
    margin-top: auto;
    font-family: monospace;
}

.write-me{
    padding-left: 10px;
    font-size: 15px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    padding-bottom: 0px;
    font-size: 15px;
}

.input{
    border: none;
    max-height: 25px;
    width: auto;
    padding-left: 10px;
    display: flex;
    align-items: center;
}

.input-message{
    border: none;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sent{
    padding-left: 10px;
}

textarea {
    padding: 8px;
}

textarea, input{
    width: 400px;
    margin-left: 10px;
    background-color: inherit;
    color: #fbfae8;
    border: none;
    outline: none;
    font-family: monospace;
    font-size: 15px;
}

.submit {
    background-color: #2d2d2d;
    color: #d4d4d4;
    font-family: monospace;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin: 20px;
    cursor: pointer;
}

.submit:hover {
    color: #191a1a;
    background-color: rgb(191, 240, 106);
}

.header-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: rgb(24, 23, 23);
    border-bottom:  solid 1px black;
    margin-top: auto;
    font-size: 15px
}

.expand, .minimize{
    padding: 5px;
    cursor: pointer;
}

.expand:hover, .minimize:hover{
    background-color: #363636;
    border-radius: 20%;
}

label {
    float: left;
    display: flex;
    align-items: center;
}

.o{
    color: rgb(177, 37, 65);
    padding-right: 5px;
}

.squiggle{
    color: rgb(67, 142, 238);
}

.ugs{
    color: rgb(191, 240, 106);
}