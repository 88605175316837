.tabs{
    display: flex;
    flex-direction: row;
    align-items: center;
    
    width: 100%;
    background-color: #232323;
}

.tab{
    display: flex;
    align-items: center;
    color: gray;
    padding: 5px;
    padding-right: 10px;
}

.tab-icon{
    font-size: 1.2rem;
    padding-left: 6px;
}

.tab-outline {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.close-tab {
    font-size: 1rem;
    color: #232323;
    cursor: pointer;
    padding: 3px;
    padding-bottom: 1px;
    width: 20px;
    height: 20px;
    text-align: center;
}

.tab:hover .close-tab{
    color: gray;
}

.close-tab:hover {
    background-color: #363636;
    border-radius: 20%;
}

.tab.selected {
    color: white;
    border-bottom: solid 1px rgb(20, 165, 165);
}

.tab.selected .close-tab {
    color: white;
}

a {
    text-decoration: none;
    color: gray;
}

li{
    margin: 10px;
}