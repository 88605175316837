.navbar {
    display: flex;
    flex-direction: row;
}

.navbar.closed {
    width: 55px;
}

.entry-name:hover{
    color: white;
    cursor: pointer;
}

.nav-btns {
    background-color: rgb(24, 23, 23);
    display: flex;
    flex-direction: column;
    width: 55px;
    height: 100%;
    font-size: xx-large;
    border: solid 1px black;
    align-items: center;
}

.nav-btn {
    color: gray;
    padding: 10px;
}

.nav-btn.files:hover {
    color: white;
    cursor: pointer;
}

.nav-btn.github:hover {
    color: #6e5494;
    cursor: pointer;
}

.nav-btn.linkedin:hover {
    color: #0077b5;
    cursor: pointer;
}

.nav-btn.mail:hover {
    color: #DB4437;
    cursor: pointer;
}

.nav-btn.download:hover {
    color: #F4B400;
    cursor: pointer;
}

.nav-sections {
    background-color: rgb(24, 23, 23);
    width: 230px;
    border-right:  solid 1px black;
    font-size: 1.rem;
    padding-top: 35px;
    overflow: auto;
    resize: horizontal;
}

.file {
    color: gray;
    margin-bottom: 10px;
}

.file:hover {
    color: white;
    cursor: pointer;
}

.folder-icon{
    padding-left: 17px;
    display: flex;
    align-items: center;
}

.file-icon {
    padding-left: 40px;
    display: flex;
    align-items: center;
}

.folder-icon svg, .file-icon svg {
    margin-right: 3px;
    font-size: 1.2rem;
}

.top{
    font-weight: 1000;
    padding-left: 15px;
    display: flex;
}

a {
    text-decoration: none;
    color: gray;
}

img{
    width: 20px;
    height: auto;
    margin-right: 5px;
}