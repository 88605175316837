body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #232323;
}

img {
  background-color: transparent;
  width: 350px;
}

.main {
  display: flex;
  width: 100%;
}

.code{
    padding-left: 42px;
    overflow: scroll;
}

.page-wrap {
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex: 1;
}

.page-layout {
  overflow: scroll;
}
