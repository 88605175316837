body{
    color: #fbfae8;
    list-style-type: none;
}

.home{
    margin: 0;
    margin-left: 75px;
    margin-top: 105px;
}

.name{
    font-size: 3rem;
    padding: 10px;
}

.title{
    font-size: 1.5rem;
    color: grey; 
    padding: 10px;
}

.about{
    padding: 10px;
    width: 700px;
}

.content {
    margin: 0;
    margin-left: 10px;
    margin-top: 50px;
    font-size: 1rem;
    line-height: 1.6;
}

.links-header{
    margin-left: 10px;
    margin-bottom: 30px;
    font-size: 1.5rem;
    margin-top: 50px;
}

.links{
    display: flex;
    flex-direction: row;
}

.tab-links{
    width: 400px;
    margin-left: 10px;
    margin-top: 50px;
}

.social-links{
    width: 200px;
    margin-left: 10px;
    margin-top: 50px;
}

.link-text{
    color: rgb(20, 165, 165);
    text-decoration: none;
}